(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
// global $bs

var spinner = {
    template: '<div class="sk-folding-cube">\n          <div class="sk-cube1 sk-cube"></div>\n          <div class="sk-cube2 sk-cube"></div>\n          <div class="sk-cube4 sk-cube"></div>\n          <div class="sk-cube3 sk-cube"></div>\n        </div>'
};

window.spinner = spinner;

var spinnerCircle = {
    template: '<div class="sk-fading-circle">\n      <div class="sk-circle1 sk-circle"></div>\n      <div class="sk-circle2 sk-circle"></div>\n      <div class="sk-circle3 sk-circle"></div>\n      <div class="sk-circle4 sk-circle"></div>\n      <div class="sk-circle5 sk-circle"></div>\n      <div class="sk-circle6 sk-circle"></div>\n      <div class="sk-circle7 sk-circle"></div>\n      <div class="sk-circle8 sk-circle"></div>\n      <div class="sk-circle9 sk-circle"></div>\n      <div class="sk-circle10 sk-circle"></div>\n      <div class="sk-circle11 sk-circle"></div>\n      <div class="sk-circle12 sk-circle"></div>\n    </div>'
};

var spinnerBounce = {
    template: '\n    <div class="spinner">\n      <div class="double-bounce1"></div>\n      <div class="double-bounce2"></div>\n    </div>'
};

var wmhsPost = {
    template: '<a :href="link" class="post-card">\n            <div class="card-image" v-bind:style="{ backgroundImage: \'url(\' + thumbnail + \')\'}"></div>\n        \t<div class="card-content" style="width:100%">\n        \t    <span class="card-title">{{ decodeHtml(title) }}</spa>\n        \t</div>\n        </a>',
    props: ['link', 'thumbnail', 'title'],
    methods: {
        decodeHtml: function decodeHtml(html) {
            var txt = document.createElement("textarea");
            txt.innerHTML = html;
            return txt.value;
        }
    }
};

var loadPostsButton = {
    template: '<div id="posts-row" class="row flex">\n        <div v-for="post in morePosts" class="col s12 m4">\n            <wmhs-post :title="post.title.rendered" :link="post.link" :thumbnail="post.thumbnail"></wmhs-post>\n        </div>\n        <div class="col s12">\n            <spinner v-show="isLoading"></spinner>\n        </div>\n        <div class="col s12 flex-center">\n          <a class="usa-button-secondary" @click="load">Load More</a>\n        </div>\n    </div>',
    props: ['service'],
    data: function data() {
        return {
            page: 2,
            isLoading: false,
            morePosts: []
        };
    },
    methods: {
        load: function load() {
            this.isLoading = true;
            var opts = {
                page: this.page,
                serviceID: this.service
            };
            $bs.dataService.getPosts(opts, this.success, this.fail);
        },
        success: function success(result) {
            // alert('yes');
            console.log(result.data);

            this.page += 1;
            for (var i in result.data) {
                var post = result.data[i];
                post.thumbnail = post.better_featured_image ? post.better_featured_image.source_url : '/wp-content/uploads/2018/01/default-featured-image-lw-icons.jpg';
                this.morePosts.push(post);
            }

            this.isLoading = false;
            console.log(this.morePosts);
        },
        fail: function fail(result) {
            this.isLoading = false;
            //alert('There are no more posts to load.');
            console.log(result);
        }
    },
    components: {
        'wmhs-post': wmhsPost,
        'spinner': spinner
    }
};

exports.loadPostsButton = loadPostsButton;

},{}],2:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.wmhsMap = wmhsMap;
/* global google, $, MarkerClusterer */

function initMap() {
    console.log('Initializing map');

    var latLng = {};
    if (window.mapData.latLng) {
        latLng = {
            lat: window.mapData.latLng.lat || 39.6539371,
            lng: window.mapData.latLng.lng || -79.9880444
        };
    } else {
        latLng = {
            lat: 39.6539371,
            lng: -79.9880444
        };
    }
    // Create a map object and specify the DOM element for display.
    window.mapObject = new google.maps.Map(document.getElementById(window.mapData.elementID), {
        center: latLng,
        scrollwheel: false,
        // Apply the map style array to the map.
        styles: window.mapData.styleArray || {},
        zoom: window.mapData.zoom || 8
    });

    var markers = [];

    var _loop = function _loop() {

        // console.log(window.LOCATIONS[i]);

        var latlng = new google.maps.LatLng(window.LOCATIONS[i].lat, window.LOCATIONS[i].lng);

        var svgIcon = {
            url: '/wp-content/uploads/2020/02/map-pin.svg',
            scaledSize: new google.maps.Size(46.76, 86.05)
        };

        var marker = new google.maps.Marker({
            position: latlng,
            map: window.mapObject,
            animation: google.maps.Animation.DROP,
            optimized: false,
            icon: svgIcon
        });

        var infowindow = new google.maps.InfoWindow({
            pixelOffset: new google.maps.Size(0, 0),
            content: '<h4>' + window.LOCATIONS[i].title + '</h4>' + ('<p><a href="' + window.LOCATIONS[i]['google_maps_link'] + '" target="_blank">Get Directions <i class="fa fa-external-link" aria-hidden="true"></i></a></p>')
        });

        marker.addListener('click', function () {
            infowindow.open(window.mapObject, marker);
        });

        window.LOCATIONS[i].marker = marker;
        window.LOCATIONS[i].infowindow = infowindow;
        markers.push(marker);
    };

    for (var i = 0; i < window.LOCATIONS.length; i++) {
        _loop();
    }

    // Add a marker clusterer to manage the markers.
    var clusterStyles = [{
        textColor: 'white',
        url: '/wp-content/uploads/2019/10/m1.png',
        height: 52,
        width: 53,
        fontFamily: "'futura-pt', 'OpenSans'"
    }];

    var markerCluster = new MarkerClusterer(window.mapObject, markers, {
        imagePath: '/wp-content/uploads/2019/10/m1.png',
        styles: clusterStyles
    });
}

function wmhsMap() {
    var pData = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

    var mapEl;
    window.mapData = pData;

    if (!mapData.elementID) {
        mapEl = $('#wmhsMap');
    } else {
        mapEl = $('#' + mapData.elementID);
    }

    if (mapEl.length >= 1) {

        // is google maps loaded?
        if (typeof google === "undefined") {
            var gMapsLoaded = false;

            window.gMapsCallback = function () {
                gMapsLoaded = true;
                $(window).trigger('gMapsLoaded');
            };

            window.loadGoogleMaps = function () {
                if (gMapsLoaded) return window.gMapsCallback();
                var script_tag = document.createElement('script');
                script_tag.setAttribute("type", "text/javascript");
                script_tag.setAttribute("src", 'https://maps.google.com/maps/api/js?key=' + mapData.apiKey + '&sensor=false&callback=gMapsCallback');
                (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
            };

            // script load event to initMap callback
            $(window).bind('gMapsLoaded', initMap);

            window.loadGoogleMaps();
        } else {
            // yes, initialize map
            initMap();
        }
    }
}

},{}],3:[function(require,module,exports){
'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }(); // Main Javascipt Source
// App class and DOMContentLoaded listener

/* global $, Swiper, smoothScroll, stickybits */

//import uswds from "../node_modules/uswds/dist/js/uswds";
// import TextResizer from "./lib/text-resizer";


var _services = require('./services');

var _services2 = _interopRequireDefault(_services);

var _wmhsMap = require('./lib/wmhsMap');

var _loadPosts = require('./components/load-posts');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

window.wmhsMap = _wmhsMap.wmhsMap;
//window.uswds = uswds;
window.$ = window.jQuery;
window.loadPostsButton = _loadPosts.loadPostsButton;

var App = function () {
	function App() {
		var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

		_classCallCheck(this, App);

		console.log('Welcome.');
		this.dataService = new _services2.default();
		// this.textResizer = new TextResizer();

		this.initUI();
	}

	// UI Funcitons


	_createClass(App, [{
		key: 'initUI',
		value: function initUI() {
			// initialize swiper
			if ($('.swiper-container').length > 0) {
				var swiper = new Swiper('.swiper-container', {
					pagination: {
						el: '.swiper-pagination'
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev'
					},
					loop: true
				});
			}

			// sticky bits
			if (window.innerWidth > 767 && window.stickybits != undefined) {
				stickybits(".blog-sb", { stickyBitStickyOffset: 5 });
				if ($('.sticky-bit').length > 0) {
					stickybits(".sticky-bit", { stickyBitStickyOffset: 5 });
				}
			}

			// open pdfs in new tab
			$('a[href*=".pdf"]').attr('target', '_blank');

			// tag breadcrumbs
			$('#breadcrumbs a').click(function () {
				var linkText = $(this).text();
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: 'tagLinkTrigger',
					'gtm.element.dataset.linktag': 'Breadcrumb Link - ' + linkText
				});
			});
		}
	}, {
		key: 'goToSelectedPage',
		value: function goToSelectedPage(select_id) {
			var url = $(select_id).val(); // get selected value
			if (url) {
				// require a URL
				window.location = url; // redirect
			}

			return false;
		}
	}, {
		key: 'toggleMobileSearch',
		value: function toggleMobileSearch() {
			console.log('toggling mobile search bar');

			var searchBar = $('#mobile-search-bar');

			searchBar.toggleClass('show');

			if (searchBar.hasClass('.show')) {
				setTimeout($('#mobile-search-input').get(0).focus(), 500);
			}
		}
	}, {
		key: 'closeMobileSearch',
		value: function closeMobileSearch() {
			var searchBar = $('#mobile-search-bar');
			searchBar.removeClass('show');
		}
	}]);

	return App;
}();

;

$(document).ready(function () {

	// if (!localStorage.getItem('seenWMHSUpdate')) {
	//     $('body').css({
	//         'overflow': 'hidden'
	//     });
	//     setTimeout(function(){
	//         $('body').prepend(
	//             `<div id="update-notice">
	//                 <div>
	//                     <a class="close"><i class="fa fa-times" aria-hidden="true"></i></a><h3>We are UPMC Western Maryland.</h3>
	// 					<p>We are now part of one of the nation's leading health systems.</p>
	// 					<button class="usa-button">Enter Site</button>
	//                 </div>
	//             </div>`
	//         );
	//         $('#update-notice').animate({opacity: 1}, 500);
	//         $('body').css({
	//             'overflow': 'hidden'
	//         });
	//         $('#update-notice, #update-notice a.close, #update-notice button.usa-button').on('click', function(){
	//             localStorage.setItem('seenWMHSUpdate', true);
	//             $('#update-notice').animate({opacity: 0}, 500, function(){
	//                 $(this).remove();
	//                 $('body').css({
	//                     'overflow': 'auto'
	//                 });
	//             });
	//         })
	//     }, 100);
	// }

	// With Video
	//
	// if (!localStorage.getItem('seenWMHSUpdate')) {
	//     $('body').css({
	//         'overflow': 'hidden'
	//     });
	//     setTimeout(function(){
	//         $('body').prepend(
	//             `<div id="update-notice">
	//                 <div>
	//                     <a class="close"><i class="fa fa-times" aria-hidden="true"></i></a><h3>We are UPMC Western Maryland.</h3>
	// 					<p>We are now part of one of the nation's leading health systems. Watch the video below for more information.</p>
	// 					<div class="resp-container">
	// 						<iframe class="resp-iframe" src="https://player.vimeo.com/video/389025413" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
	// 					</div>
	// 					<button class="usa-button">Enter Site</button>
	//                 </div>
	//             </div>`
	//         );
	//         $('#update-notice').animate({opacity: 1}, 500);
	//         $('body').css({
	//             'overflow': 'hidden'
	//         });
	//         $('#update-notice, #update-notice a.close, #update-notice button.usa-button').on('click', function(){
	//             localStorage.setItem('seenWMHSUpdate', true);
	//             $('#update-notice').animate({opacity: 0}, 500, function(){
	//                 $(this).remove();
	//                 $('body').css({
	//                     'overflow': 'auto'
	//                 });
	//             });
	//         })
	//     }, 100);
	// }

	// create new App object
	var $bs = new App();
	window.$bs = $bs;

	$(".living-well-links a[href]").each(function () {
		if (this.href == window.location.href) {
			$(this).addClass("currentPage");
		}
	});
});

},{"./components/load-posts":1,"./lib/wmhsMap":2,"./services":4}],4:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
    value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

// Data Services API
/* global axios, $ */

var DataServices = function () {
    function DataServices() {
        _classCallCheck(this, DataServices);
    }

    // catch all fails


    _createClass(DataServices, [{
        key: "catchAll",
        value: function catchAll(results) {
            console.log(results);
        }
    }, {
        key: "getSnazzyMap",
        value: function getSnazzyMap() {
            return [{
                "featureType": "administrative.province",
                "elementType": "geometry.stroke",
                "stylers": [{
                    "visibility": "on"
                }, {
                    "color": "#5b7095"
                }]
            }, {
                "featureType": "administrative.province",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#5b7095"
                }]
            }, {
                "featureType": "administrative.locality",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#002d72"
                }]
            }, {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#f1f1f1"
                }]
            }, {
                "featureType": "landscape.natural.landcover",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#cccccc"
                }, {
                    "visibility": "on"
                }]
            }, {
                "featureType": "landscape.natural.terrain",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#c0d0e8"
                }, {
                    "visibility": "on"
                }, {
                    "lightness": "50"
                }]
            }, {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#5e7b42"
                }, {
                    "lightness": "-20"
                }]
            }, {
                "featureType": "poi.attraction",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#4c5b8a"
                }]
            }, {
                "featureType": "poi.government",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#ced7db"
                }]
            }, {
                "featureType": "poi.medical",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#4c5b8a"
                }, {
                    "lightness": "60"
                }]
            }, {
                "featureType": "poi.medical",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#002d72"
                }]
            }, {
                "featureType": "poi.park",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#5b7095"
                }, {
                    "lightness": "80"
                }]
            }, {
                "featureType": "poi.place_of_worship",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#d6cbc7"
                }]
            }, {
                "featureType": "poi.school",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#c4c9e8"
                }]
            }, {
                "featureType": "poi.sports_complex",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#b1eaf1"
                }]
            }, {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [{
                    "lightness": "100"
                }]
            }, {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [{
                    "visibility": "simplified"
                }]
            }, {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#ba6100"
                }, {
                    "lightness": "60"
                }]
            }, {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [{
                    "color": "#ffe9d2"
                }]
            }, {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [{
                    "color": "#5b616b"
                }]
            }, {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [{
                    "visibility": "on"
                }, {
                    "lightness": "100"
                }]
            }, {
                "featureType": "water",
                "elementType": "all",
                "stylers": [{
                    "color": "#006598"
                }, {
                    "lightness": "72"
                }]
            }];
        }
    }, {
        key: "getPractitioners",
        value: function getPractitioners(opts, successCallback, catchCallback) {
            var params = {
                per_page: opts.per_page ? opts.per_page : 100,
                paged: opts.paged ? opts.paged : 1,
                name: opts.name ? opts.name : '',
                serviceID: opts.serviceID ? opts.serviceID : ''
            };

            axios.get('/wp-json/wmhs/v1/practitioners', {
                params: params,
                responseType: 'json'
            }).then(successCallback).catch(catchCallback);
        }
    }, {
        key: "getPractitionersByService",
        value: function getPractitionersByService(tagID, successCallback, catchCallback) {
            axios.get('/wp-json/wp/v2/practitioner', {
                params: {
                    service: tagID,
                    order: 'asc',
                    orderby: 'title',
                    per_page: 100
                },
                responseType: 'json'
            }).then(successCallback).catch(catchCallback);
        }
    }, {
        key: "getPosts",
        value: function getPosts(opts, successCallback, catchCallback) {
            var params = {
                per_page: opts.per_page ? opts.per_page : 6,
                page: opts.page,
                endpoint: opts.endpoint ? opts.endpoint : 'posts'
            };

            if (opts.serviceID) {
                params['service'] = opts.serviceID;
            }

            axios.get("/wp-json/wp/v2/" + params.endpoint, {
                params: params,
                responseType: 'json'
            }).then(successCallback).catch(catchCallback);
        }
    }, {
        key: "getEvents",
        value: function getEvents(opts, successCallback, catchCallback) {
            var params = {
                date: opts.date,
                paged: opts.paged,
                service: opts.service
            };

            if (opts.serviceID) {
                params['service'] = opts.serviceID;
            }

            axios.get("/wp-json/wmhs/v1/events", {
                params: params,
                responseType: 'json'
            }).then(successCallback).catch(catchCallback);
        }
    }, {
        key: "getHealthLibraryTerm",
        value: function getHealthLibraryTerm(opts, successCallback, catchCallback) {

            var params = {
                query: opts.query ? opts.query : ''
            };

            axios.get("/wp-json/wmhs/v1/library", {
                params: params,
                responseType: 'text'
            }).then(successCallback).catch(catchCallback);
        }
    }]);

    return DataServices;
}();

exports.default = DataServices;

},{}]},{},[3]);
